import {createTheme, responsiveFontSizes} from "@mui/material";
import '../../App.css'

let visionaryTheme = createTheme({
    typography: {
        fontFamily: ['Unbounded'],
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'black',
                    backgroundColor: '#fff',
                    borderRadius: '50rem',
                    border:'2px solid black',
                    '&:hover': {
                        backgroundColor: '#fbeb4f',
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        border: 'none !important',
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: 'black !important',
                        top: '-0.5rem !important',
                    },
                    '&.MuiInputLabel-shrink': {
                        top: '-0.5rem !important',
                    }
                }
            }
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#000'
        },
        background: {
            default: '#fbeb4f'
        },
        divider: "#fbeb4f"
    }
})

visionaryTheme = responsiveFontSizes(visionaryTheme)

export default visionaryTheme
