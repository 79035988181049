export const stepOneMap = [
    {
        id: 'infoPersonali',
        title: 'Forniscici alcune informazioni su di te e ci vedremo presto:',
        fields: [
            {
                id: 'nome',
                name: 'nome',
                label: 'Nome',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'cognome',
                name: 'cognome',
                label: 'Cognome',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'email',
                name: 'email',
                label: 'Indirizzo email',
                type: 'email',
                validations: ['string', 'required'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                },
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'telefono',
                name: 'telefono',
                label: 'Numero di telefono',
                type: 'text',
                validations: ['string', 'required'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                },
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
        ]
    },
]
