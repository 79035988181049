import React, {useEffect} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {API_URL} from "../config";
import {Box, Container, Fade, Grid, Stack, Typography} from "@mui/material";
import {Form} from "../components/Form";
import {stepOneMap} from "../assets/data/stepOneMap";
import {stepTwoMap} from "../assets/data/stepTwoMap";

export const QrPage = () => {
    const {id} = useParams()
    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)

    useEffect(() => {
        getData().then()
    },[])

    const getData = async () => {
        setLoading(true)
        await axios.get(`${API_URL}/profile/${id}`)
            .then(res => {
                setData(res.data.profile)
            })
            .catch(err => {
                setError(true)
            })
        setLoading(false)
    }

    return (
        <Container maxWidth={'xl'}>
            <Box sx={{py:2}}>
                <Stack spacing={2} direction={{xs:'column', md: 'row',}} justifyContent={'space-between'} alignItems={{xs: 'start', md: 'center'}} sx={{pb:5}}>
                    <Box sx={{width:'15rem'}}>
                        <img src={require('../assets/images/logo.png')} alt={'visionary-logo'} style={{width:'100%'}}/>
                    </Box>
                    <Box sx={{border:'1px solid black', borderRadius:'50rem', background:'white', px:3, py:2, alignSelf:'center'}}>
                        <Typography fontWeight={'900'}>
                            ci vediamo il 4 novembre
                        </Typography>
                    </Box>
                </Stack>
                <Box>
                    <Box sx={{textAlign:'center'}}>
                        <Typography textAlign={'center'} variant={'h6'} fontWeight={'bold'}>
                            Ciao, {data.nome} {data.cognome}
                            <br/>
                            Trovi qui sotto il tuo QR code per partecipare
                        </Typography>
                        <Box sx={{width:'15rem', height:'15rem', margin:'auto', pt:2}}>
                            <img src={data.qrCodeUrl} alt={'visionary-logo'} style={{width:'100%', overflow:'auto', objectFit:'cover', borderRadius:'2rem', border:'2px solid black'}}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}