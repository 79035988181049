import React from "react";
import {Box, Typography, Grid} from "@mui/material";
import {FormField} from "./FormField";
import {stepOneMap} from "../assets/data/stepOneMap";

const Title = ({title}) => {
    return (<Box sx={{pb: 1}}>
        <Typography fontWeight={'bold'} variant={'h6'}>
            {title}
        </Typography>
    </Box>)
}

export const FormContent = ({formik, map}) => {
    return (
        <Box>
            {map.map((section, index) => {
                return <Box key={index} sx={{py: 1}}>
                    <Title title={section.title}/>
                    <Grid container spacing={2}>
                        {section.fields.map((field, index) => {
                                if (field.conditions) {
                                    let render = []
                                    field.conditions.predicates.forEach(predicate => {
                                        let checked = predicate.values.includes(formik.values[predicate.id])
                                        render.push(checked)
                                    })
                                    if (!render.includes(false)) {
                                        return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                          label={field.label}
                                                          type={field.type} values={field.values ?? []}
                                                          cols={field.cols}
                                                          precompiled={field.precompiled}
                                                          disablePast={field.disablePast}
                                                          disableFuture={field.disableFuture}
                                                          row={field.row}
                                        />
                                    }
                                } else {
                                    return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                      label={field.label}
                                                      type={field.type} values={field.values ?? []}
                                                      cols={field.cols}
                                                      precompiled={field.precompiled}
                                                      disablePast={field.disablePast}
                                                      disableFuture={field.disableFuture}
                                                      row={field.row}
                                    />
                                }
                            }
                        )}
                    </Grid>
                </Box>
            })}
        </Box>
    )
}
