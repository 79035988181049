export const stepTwoMap = [
    {
        id: 'stepTwo',
        title: 'Dicci qualcosa di più su di te:',
        fields: [
            {
                id: 'annoNascita',
                name: 'annoNascita',
                label: 'Anno di nascita',
                type: 'number',
                validations: ['number', 'required','when'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        [], {
                            then: (schema) => schema
                                .max(2005, 'Devi avere almeno 18 anni per partecipare')
                        }
                    ]
                },
            },
            {
                id:"genere",
                name:"genere",
                label:"Genere",
                type:"select",
                validations:["string", "required"],
                required:true,
                precompiled:false,
                params:{
                    required:"Questo campo è obbligatorio",
                },
                values:[
                    {
                        value:"uomo",
                        label:"Uomo",
                    },
                    {
                        value:"donna",
                        label:"Donna",
                    },
                    {
                        value:"nonBinary",
                        label:"Non binary",
                    }
                ]
            },
            {
                id:"occupazione",
                name:"occupazione",
                label:"Attualmente",
                type:"select",
                validations:["string", "required"],
                required:true,
                precompiled:false,
                params:{
                    required:"Questo campo è obbligatorio",
                },
                values:[
                    {
                        value:"lavoro",
                        label:"Lavoro",
                    },
                    {
                        value:"studio",
                        label:"Studio",
                    },
                    {
                        value:"entrambi",
                        label:"Entrambi",
                    },
                    {
                        value:"nessuno",
                        label:"Nessuno dei due",
                    }
                ]
            },
            {
                id:"frequenti",
                name:"frequenti",
                label:"Attualmente frequenti:",
                type:"select",
                validations:["string", "when"],
                required:true,
                precompiled:false,
                params: {
                    when: [
                        ['occupazione'], {
                            is: (occupazione) => occupazione === "studio" || occupazione === "entrambi",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]

                },
                values:[
                    {
                        value:"scuola",
                        label:"Scuola",
                    },
                    {
                        value:"universita",
                        label:"Università",
                    }
                ],
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: 'occupazione',
                            values: ['studio', 'entrambi']
                        }
                    ]
                }
            },
            {
                id:"scuola",
                name:"scuola",
                label:"Che scuola frequenti?",
                type:"select",
                validations:["string","when"],
                required:true,
                precompiled:false,
                params: {
                    when: [
                        ['occupazione', "frequenti"], {
                            is: (occupazione, frequenti) => (occupazione === "studio" || occupazione === "entrambi") && frequenti === "scuola",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]

                },
                values:[
                    {value:"liceoArtistico", label:"Liceo artistico"},
                    {value:"liceoClassico", label:"Liceo classico"},
                    {value:"liceoLinguistico", label:"Liceo linguistico"},
                    {value:"liceiMusicaliECoreutici", label:"Licei musicali e coreutici"},
                    {value:"liceoScientifico", label:"Liceo scientifico"},
                    {value:"liceoScientificoOpzioneScienzeApplicate", label:"Liceo scientifico – opzione scienze applicate"},
                    {value:"liceoScientificoSezioneAIndirizzoSportivo", label:"Liceo scientifico – sezione a indirizzo sportivo"},
                    {value:"liceoScienzeUmane", label:"Liceo scienze umane"},
                    {value:"liceoScienzeUmaneOpzioneEconomicoSociale", label:"Liceo scienze umane – opzione economico sociale"},
                    {value:"liceoDelleScienzeUmane", label:"Liceo delle scienze umane"},
                    {value:"istitutoTecnico", label:"Istituto tecnico"},
                    {value:"istitutoProfessionale", label:"Istituto professionale"},
                    {value:"istruzioneEFormazioneProfessionale", label:"Istruzione e formazione professionale"},
                    {value:"altro", label:"Altro"},
                ],
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: 'occupazione',
                            values: ['studio', 'entrambi']
                        },
                        {
                            id: 'frequenti',
                            values: ['scuola']
                        }
                    ]
                }
            },
            {
                id:"universita",
                name:"universita",
                label:"Che università frequenti?",
                type:"select",
                validations:["string", "when"],
                required:true,
                precompiled:false,
                params: {
                    when: [
                        ['occupazione', "frequenti"], {
                            is: (occupazione, frequenti) => (occupazione === "studio" || occupazione === "entrambi") && frequenti === "universita",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]

                },
                values:[
                    {value:"agraria",label: "Agraria"},
                    {value:"architettura",label: "Architettura"},
                    {value:"artistico",label: "Artistico"},
                    {value:"chimicoFarmaceutico",label: "Chimico-farmaceutico"},
                    {value:"difesaESicurezza",label: "Difesa e sicurezza"},
                    {value:"economicoStatistico",label: "Economico-statistico"},
                    {value:"educazioneFisica",label: "Educazione fisica"},
                    {value:"educativoPedagogico",label: "Educativo – pedagogico"},
                    {value:"fisicoMatematico",label: "Fisico- matematico"},
                    {value:"filosofico",label: "Filosofico"},
                    {value:"geoBiologico",label: "Geo-biologico"},
                    {value:"giuridico",label: "Giuridico"},
                    {value:"ingegneria",label: "Ingegneria"},
                    {value:"letterario",label: "Letterario"},
                    {value:"linguistico",label: "Linguistico"},
                    {value:"medicoMedicinaEOdontoiatria",label: "Medico/medicina e odontoiatria"},
                    {value:"medicoProfessioniSanitarie",label: "Medico/professioni sanitarie"},
                    {value:"politicoSociale",label: "Politico-sociale"},
                    {value:"psicologico",label: "Psicologico"},
                    {value:"storico",label: "Storico"},
                    {value:"veterinario",label: "Veterinario"},
                    {value:"altro",label: "Altro"},
                ],
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: 'occupazione',
                            values: ['studio', 'entrambi']
                        },
                        {
                            id: 'frequenti',
                            values: ['universita']
                        }
                    ]
                }
            },
            {
                id:"regione",
                name:"regione",
                label:"In quale regione d’Italia vivi?",
                type:"select",
                validations:["string", "required"],
                required:true,
                precompiled:false,
                params:{
                    required:"Questo campo è obbligatorio",
                },
                values:[
                    {value:"abruzzo",label: "Abruzzo"},
                    {value:"basilicata",label: "Basilicata"},
                    {value:"calabria",label: "Calabria"},
                    {value:"campania",label: "Campania"},
                    {value:"emiliaRomagna",label: "Emilia-Romagna"},
                    {value:"friuliVeneziaGiulia",label: "Friuli-Venezia Giulia"},
                    {value:"lazio",label: "Lazio"},
                    {value:"liguria",label: "Liguria"},
                    {value:"lombardia",label: "Lombardia"},
                    {value:"marche",label: "Marche"},
                    {value:"molise",label: "Molise"},
                    {value:"piemonte",label: "Piemonte"},
                    {value:"puglia",label: "Puglia"},
                    {value:"sardegna",label: "Sardegna"},
                    {value:"sicilia",label: "Sicilia"},
                    {value:"toscana",label: "Toscana"},
                    {value:"trentinoAltoAdige",label: "Trentino-Alto Adige"},
                    {value:"umbria",label: "Umbria"},
                    {value:"valleDAosta",label: "Valle d'Aosta"},
                    {value:"veneto",label: "Veneto"},
               ]
            },
            {
                id:"citta",
                name:"citta",
                label:"In quale città abiti?",
                type:"text",
                validations:["string", "required"],
                required:true,
                precompiled:false,
                params:{
                    required:"Questo campo è obbligatorio",
                }
            }
        ],
    },
    {
        id:'privacy',
        title:'Privacy',
        fields:[
            {
                id:"privacy",
                name:"privacy",
                label:"Acconsento al trattamento dei dati personali",
                type:"checkbox",
                validations: ['bool', "required", "oneOf"],
                required: true,
                precompiled: false,
                params: {
                    oneOf: [
                        [true], "Questo campo è obbligatorio"
                    ]
                }
            }
        ]
    },
    {
        id:'qualeAffermazione',
        title:'Scrivi la frase che più ti rappresenta:',
        fields:[
            {
                id:"affermazione",
                name:"affermazione",
                label:"",
                placeholder:"Scrivi qui",
                type:"longText",
                validations:["string", "required"],
                required:true,
                precompiled:false,
                params:{
                    required:"Questo campo è obbligatorio",
                },
                cols:{
                    xs:12,
                    sm:12
                }
            }
        ]
    }
]