import React from "react";
import {Box, Button, CircularProgress, Container, Snackbar, Typography} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import {Formik} from "formik";
import {createValidationSchema} from "../functions/createValidationScheme";
import {FormContent} from "./FormContent";
import axios from "axios";
import {API_URL} from "../config";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Form = ({map, section, setSecondStep, setData, data}) => {
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [sending, setSending] = React.useState(false)
    const [errorText, setErrorText] = React.useState('')

    const initialValues = () => {
        let d = {}
        map.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = null
                    } else if (f.type === 'checkbox') {
                        d[key] = false
                    } else {
                        d[key] = ''
                    }
                }
            )
        })
        return d
    }

    const yupSchema = () => {
        let fields = []
        map.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                fields.push(f)
            })
        })
        let schema = createValidationSchema(fields)
        return schema
    }

    const validateSchema = yupSchema();

    const sendData = async (values) => {
        if (section === 'one') {
            setSecondStep(true)
            setData(values)
            return
        }
        setSending(true)
        let datas = {...data, ...values}
        if(!['studio', "entrambi"].includes(datas['occupazione'])){
            datas['frequenti'] = ''
            datas['scuola'] = ''
            datas['universita'] = ''
         }
        if(datas['frequenti']==='scuola'){
            datas['universita'] = ''
        }
        if(datas['frequenti']==='universita'){
            datas['scuola'] = ''
        }
        await axios.post(`${API_URL}/register`, datas)
            .then(res => {
                setSuccess(true)
            })
            .catch(err => {
                setError(true)
                setErrorText(err.response.data.error)
            })
        setSending(false)
    }

    return(
        <Box>
            <Snackbar open={error} autoHideDuration={6000}>
                <Alert severity="error" sx={{width: '100%'}}>
                    {errorText !== '' ? errorText : "C'è stato un errore, si prega di riprovare"}
                </Alert>
            </Snackbar>
            <Box>
                {success ?
                    <Box>
                        <Container maxWidth={'sm'}>
                            <img style={{width: '100%'}} src={require('../assets/images/visual.png')}
                                 alt={'visual'}/>
                        </Container>
                        <Box sx={{pt:3}}>
                            <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                GRAZIE PER ESSERTI ISCRITT*
                            </Typography>
                            <Typography textAlign={'center'} sx={{py:1}}>
                                CI VEDIAMO <b>SABATO 4 NOVEMBRE</b> AI <b>MAGAZZINI DEL COTONE A GENOVA</b>
                            </Typography>
                            <Typography variant={'h6'} fontWeight={'bold'} textAlign={'center'}>
                                #StayVisionary
                            </Typography>
                        </Box>
                    </Box>
                    :
                    <Box>
                        <Formik
                            validationSchema={validateSchema}
                            initialValues={{
                                ...initialValues()
                            }}
                            validateOnChange={true}
                            validateOnBlur={false}
                            validateOnMount={false}
                            onSubmit={values => {
                                sendData(values)
                            }}
                        >
                            {(formik) =>
                                <form onSubmit={formik.handleSubmit}>
                                    <FormContent formik={formik} map={map}/>
                                    <Box sx={{textAlign: 'center', pt: 2}}>
                                        {sending ? <CircularProgress/> :
                                            <Button variant={'contained'}
                                                    sx={{width: 'min(20rem, 100%)', fontSize: '1.4rem'}}
                                                    type={'submit'}>
                                                {section==='one' ? 'avanti' : 'invia'}
                                            </Button>
                                        }
                                    </Box>
                                </form>
                            }
                        </Formik>
                    </Box>}
            </Box>
        </Box>
    )
}
