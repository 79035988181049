import React from "react";
import {Box} from "@mui/material";

export const CustomTextField = ({children, error}) => {
    return(
        <Box sx={{width:'100%', position:'relative', height:'3.5rem', mb: error ? 2 : 0}}>
            <Box sx={{zIndex:99, position:'absolute', width:'100%'}}>
                {children}
            </Box>
            <Box className={'dotted-background'}/>
        </Box>
    )
}