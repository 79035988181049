import './App.css';
import {CssBaseline} from "@mui/material";
import {Iscrizione} from "./pages/Iscrizione";
import {Route, Routes} from "react-router-dom";
import {QrPage} from "./pages/QrPage";

function App() {
    return (
        <div className="App" style={{position: 'relative'}}>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<Iscrizione/>}/>
                <Route path={'/iscrizione/:id'} element={<QrPage/>}/>
            </Routes>
        </div>
    );
}

export default App;
