import React from "react";
import {Box, Container, Fade, Grid, Slide, Stack, Typography} from "@mui/material";
import {Form} from "../components/Form";
import {stepOneMap} from "../assets/data/stepOneMap";
import {stepTwoMap} from "../assets/data/stepTwoMap";

export const Iscrizione = () => {
    const [data, setData] = React.useState({})
    const [secondStep, setSecondStep] = React.useState(false)

    return (
        <Container maxWidth={'xl'}>
            <Box sx={{py:2}}>
                <Stack spacing={2} direction={{xs:'column', md: 'row',}} justifyContent={'space-between'} alignItems={{xs: 'start', md: 'center'}} sx={{pb:5}}>
                    <Box sx={{width:'15rem'}}>
                        <img src={require('../assets/images/logo.png')} alt={'visionary-logo'} style={{width:'100%'}}/>
                    </Box>
                    <Box sx={{border:'1px solid black', borderRadius:'50rem', background:'white', px:3, py:2, alignSelf:'center'}}>
                        <Typography fontWeight={'900'}>
                            ci vediamo il 4 novembre
                        </Typography>
                    </Box>
                </Stack>
                {!secondStep && <Fade in={!secondStep} timeout={600} appear={false}>
                    <Grid container spacing={{xs: 1, md: 8}} direction={{xs: 'column-reverse', md: 'row'}}>
                        <Grid item xs={12} md={4}>
                            <Box>
                                <img src={require('../assets/images/mare.png')} style={{width: '100%'}} alt={'claim'}/>
                            </Box>
                            <Form section={'one'} map={stepOneMap} setData={setData} data={data}
                                  setSecondStep={setSecondStep}/>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box>
                                <img style={{width: '100%'}} src={require('../assets/images/visual.png')}
                                     alt={'visual'}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Fade>}
                {secondStep && <Fade in={secondStep} timeout={600} appear={true}>
                    <Box>
                        <Form section={'two'} map={stepTwoMap} setData={setData} data={data}
                              setSecondStep={setSecondStep}/>
                    </Box>
                </Fade>}
            </Box>
        </Container>
    )
}
